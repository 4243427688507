import React from 'react'
import Head from '../components-en/head'
import Layout from '../components-en/layout'

import '../styles/grid.scss'
import '../styles/index.scss'



const JoinPage = () => {
 
  return(
 
    <Layout >
      <Head title="Invitation" description="Join now!" />

      <div className="row ctaBanner davet">
        <div className="row">
            <div className="col-2 hidden-sm"></div>
            <div className="col-1">
           
            <span role="img" aria-label="post"> 
            📬
            </span>
            </div>
            <div className="col-6">
              <div className="col-12 ">
                <h2>Join our community </h2>
               </div>

              
               <div className="col-12">
               <form  action="/success"  name="davetiye-join" method="post" netlify-honeypot="bot-field" data-netlify="true" >
                 <input type="hidden" name="form-name" value="davetiye-join" />
                <input required type="email" name="email" placeholder="Your e-mail address" />
                <button>Join Now</button>
              
               </form>
               </div>
            </div>
            <div className="col-1">
                <span role="img" aria-label="post"> 
                    ✉️
                </span>
            </div>
            <div className="col-2 hidden-sm"></div>
        </div>
      </div>
     

      


    </Layout>
  )
}

export default JoinPage